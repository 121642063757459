import React from 'react';

import Layout from '@components/Layout/Layout';
import Seo from '@components/Seo/Seo';
import TermsOfService from '@components/TermsOfService/TermsOfService';

const TermsOfServicePage = ({ location }) => {
  return (
    <Layout location={location} map>
      <Seo title="Termini di Servizio - Tiziano Bieller Sport" lang="it" />
      <TermsOfService />
    </Layout>
  );
};

export default TermsOfServicePage;
