import React from 'react';

import regolamento from '@static/pdf/regolamento-noleggio-ebike.pdf';

import Button from '@components/Button/Button';

import * as styles from './TermsOfService.module.css';

const TermsOfService = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Regolamento e Termini di Servizio</h1>

      <div className={styles.section}>
        Il noleggio di E-Bike è soggetto al Regolamento scaricabile dal link qui
        sotto:
      </div>

      <div className={styles.section}>
        <Button to={regolamento} blank>
          SCARICA IL REGOLAMENTO
        </Button>
      </div>
    </div>
  );
};

export default TermsOfService;
